import { fetchChannels } from "@/services/api/channels";
import { fetchCountries } from "@/services/api/country";
import {
  deletePendingSelectedChannels,
  fetchSelectedChannels,
  postCheckout,
  postSelectedChannels,
} from "@/services/api/cart";
import { syncStoreChannels } from "@/store/channel-selector/sync-service";
import router from "@/router";
import { apiErrorHandler, roundPrice, sortArray } from "@/services/utils/util";

const CART_TOKEN_STORAGE_KEY = "cart-token";

export const YEAR_INTERVAL_UNIT = "YEAR";
export const MONTH_INTERVAL_UNIT = "MONTH";

const getInitState = () => ({
  cart: {
    cartToken: localStorage.getItem(CART_TOKEN_STORAGE_KEY) || null,
    subscribedChannelLimit: 0,
    hasTrial: true,
    selectedCountryIds: [],
    activeChannelIds: [],
    selectedChannelIds: [],
    cartInfo: {
      subscriptionInfo: {
        total: 0,
        paymentFee: 0,
        items: [],
      },
      upgradeInfo: {
        total: 0,
        paymentFee: 0,
        items: [],
      },
      isUpgrade: false,
      intervalUnit: "YEAR",
      currency: "EUR",
      isTrialAllowed: false,
    },
  },
  countries: [],
  selectedCountryId: null,
  channelsBySelectedCountryId: [],

  selectedCountryIds: [],
  selectedChannelIds: [],

  channelLoading: false,
  countryLoading: false,
  syncLoading: false,
  errors: null,
});

//TODO refactor code duplications
export default {
  namespaced: true,
  state: getInitState(),
  getters: {
    getDeletedChannelIds: (state) =>
      state.cart.activeChannelIds.filter(
        (activeChannelId) =>
          !state.selectedChannelIds.some(
            (selectedChannelId) => selectedChannelId === activeChannelId
          )
      ),
    getNewChannelIds: (state) =>
      state.selectedChannelIds.filter(
        (selectedChannelId) =>
          !state.cart.activeChannelIds.some(
            (activeChannelId) => selectedChannelId === activeChannelId
          )
      ),

    getActiveChannelIds: (state) => state?.cart.activeChannelIds,

    getSyncLoading: (state) => state?.syncLoading,
    getLoading: (state) => state?.syncLoading,
    getChannelLoading: (state) => state?.channelLoading,
    getCountryLoading: (state) => state?.countryLoading,
    getCartInfo: (state) => state?.cart,
    getSubscriptionInfo: (state) => state?.cart?.cartInfo?.subscriptionInfo,
    getUpgradeInfo: (state) => state?.cart?.cartInfo?.upgradeInfo,
    getIsUpgrade: (state) => state?.cart?.cartInfo?.isUpgrade,
    getUpgradeFee: (state) =>
      roundPrice(state?.cart?.cartInfo?.upgradeFee ?? 0),
    getIntervalUnit: (state) =>
      state?.cart?.cartInfo?.intervalUnit ?? YEAR_INTERVAL_UNIT,
    getCountryList: (state) => state.countries,
    geSelectedCountries: (state) =>
      state.countries?.filter((country) =>
        state.selectedCountryIds.includes(country?.id)
      ),
    geSelectedCountryIds: (state) => state.selectedCountryIds,
    getSelectedCountry: (state) =>
      state.selectedCountryId
        ? state.countries?.find(({ id }) => id === state.selectedCountryId)
        : null,
    getSelectedCountryChannels: (state) => state.channelsBySelectedCountryId,
    geSelectedChannelIds: (state) => state.selectedChannelIds,
  },
  mutations: {
    CLEAR(state) {
      const initState = getInitState();
      Object.keys(initState).forEach((key) => {
        state[key] = initState[key];
      });
    },
    UPDATE_COUNTRIES(state, { countries }) {
      state.countries = countries;
    },
    UPDATE_CHANNELS(state, { channels }) {
      state.channelsBySelectedCountryId = channels;
    },
    UPDATE_SELECTED_CHANNELS(state, { selectedChannelIds }) {
      state.selectedChannelIds = selectedChannelIds;
    },
    UPDATE_SELECTED_COUNTRIES(state, { selectedCountryIds }) {
      state.selectedCountryIds = selectedCountryIds;
    },
    UPDATE_CHANNEL_SELECTOR_COUNTRY(state, { countryId, selected }) {
      if (selected) {
        state.selectedCountryIds.push(countryId);
        state.selectedCountryId = countryId;
        return;
      }
      state.selectedCountryIds = [
        ...state.selectedCountryIds.filter((id) => id !== countryId),
      ];
      if (state.selectedCountryId === countryId) {
        state.selectedCountryId = state.selectedCountryIds?.[0];
      }
    },
    UPDATE_CHANNEL_SELECTOR_SELECTED_COUNTRY(state, selectedCountryId) {
      state.selectedCountryId = selectedCountryId;
    },
    UPDATE_CHANNEL_SELECTOR_CART_INFO(state, { cart }) {
      state.cart = cart;
      localStorage.setItem(CART_TOKEN_STORAGE_KEY, cart.cartToken);
    },
    UPDATE_CHANNEL_SELECTOR_ERROR(state, errors) {
      state.errors = errors;
    },
    UPDATE_CHANNEL_SELECTOR_COUNTRY_LOADING(state, loading) {
      state.countryLoading = loading;
    },
    UPDATE_CHANNEL_SELECTOR_CHANNEL_LOADING(state, loading) {
      state.channelLoading = loading;
    },
    UPDATE_CHANNEL_SELECTOR_SYNC_LOADING(state, syncLoading) {
      state.syncLoading = syncLoading;
    },
  },
  actions: {
    async clearStore({ commit, dispatch }) {
      localStorage.removeItem(CART_TOKEN_STORAGE_KEY);
      commit("CLEAR");
      dispatch("initializeChannelSelector");
    },
    async checkout({ commit }) {
      commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", true);
      try {
        const result = await postCheckout();
        window.location.href = result.data.data.redirect;
      } catch (e) {
        console.error(e);
        commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", false);
      }
    },
    async fetchCart({ dispatch, state }) {
      try {
        const {
          data: { data: cart },
        } = await fetchSelectedChannels({
          cartToken: state?.cart?.cartToken,
        });
        await dispatch("refreshCartData", { cart });
      } catch (e) {
        console.error(e);
      }
    },

    async refreshCartData({ commit, dispatch, state }, { cart }) {
      commit("UPDATE_CHANNEL_SELECTOR_CART_INFO", { cart });
      commit("UPDATE_SELECTED_COUNTRIES", cart);
      commit("UPDATE_SELECTED_CHANNELS", cart);
      const selectedCountryId = cart.selectedCountryIds?.[0];
      if (selectedCountryId) {
        await dispatch("selectCountry", { countryId: selectedCountryId });
      }
      commit("UPDATE_CHANNELS", {
        channels: state.channelsBySelectedCountryId.map((channel) => {
          return {
            ...channel,
            selected: state.selectedChannelIds.includes(channel.id),
          };
        }),
      });
    },

    async selectCountry({ commit, state }, { countryId }) {
      console.log("countryId", { countryId });
      commit("UPDATE_CHANNEL_SELECTOR_CHANNEL_LOADING", true);
      try {
        commit("UPDATE_CHANNEL_SELECTOR_SELECTED_COUNTRY", countryId);
        const {
          data: { data: channels = [] },
        } = await fetchChannels({ all: true, country_id: countryId });
        commit("UPDATE_CHANNELS", {
          channels: sortArray(
            channels.map((channel) => ({
              ...channel,
              selected: state.selectedChannelIds.includes(channel.id),
            })),
            "name"
          ).reverse(),
        });
      } catch (e) {
        console.error(e);
      } finally {
        commit("UPDATE_CHANNEL_SELECTOR_CHANNEL_LOADING", false);
      }
    },
    async initializeChannelSelector({ commit, state, dispatch, rootState }) {
      commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", true);
      commit("UPDATE_CHANNEL_SELECTOR_COUNTRY_LOADING", true);
      commit("UPDATE_CHANNEL_SELECTOR_CHANNEL_LOADING", true);
      try {
        const {
          data: { data: countries = [] },
        } = await fetchCountries({ all: true });
        commit("UPDATE_COUNTRIES", { countries });
        commit("UPDATE_CHANNEL_SELECTOR_COUNTRY_LOADING", false);

        if (state?.cart?.cartToken || rootState.auth.user) {
          const {
            data: { data: cart },
          } = await fetchSelectedChannels({
            cartToken: state?.cart?.cartToken,
          });
          await dispatch("refreshCartData", { cart });
        }
      } catch (e) {
        commit("UPDATE_CHANNEL_SELECTOR_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", false);
        commit("UPDATE_CHANNEL_SELECTOR_COUNTRY_LOADING", false);
        commit("UPDATE_CHANNEL_SELECTOR_CHANNEL_LOADING", false);
      }
    },
    async syncChannels({ commit, state }, diff) {
      commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", true);
      try {
        const {
          data: { data: cart },
        } = await syncStoreChannels(diff, state.cart.cartToken);
        commit("UPDATE_CHANNEL_SELECTOR_CART_INFO", { cart });
      } catch (e) {
        commit("UPDATE_CHANNEL_SELECTOR_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", false);
      }
    },
    async addCountry({ commit, dispatch }, country) {
      commit("UPDATE_CHANNEL_SELECTOR_COUNTRY", {
        countryId: country?.id,
        selected: true,
      });
      dispatch("selectCountry", { countryId: country.id });
    },
    async removeCountry({ commit, dispatch, state }, { country, channels }) {
      const selectedChannelIds = channels
        .filter(({ id: channelId }) =>
          state.selectedChannelIds.includes(channelId)
        )
        .map(({ id }) => id);
      if (selectedChannelIds?.length > 0) {
        commit("UPDATE_SELECTED_CHANNELS", {
          selectedChannelIds: [
            ...state.selectedChannelIds.filter(
              (channelId) => !selectedChannelIds.includes(channelId)
            ),
          ],
        });
        commit("UPDATE_CHANNELS", {
          channels: state.channelsBySelectedCountryId.map((channel) => {
            if (!selectedChannelIds.includes(channel?.id)) {
              return channel;
            }
            return {
              ...channel,
              selected: false,
            };
          }),
        });
        dispatch("syncChannels", {
          remove: selectedChannelIds,
        });
      }
      if (state.selectedCountryId === country.id) {
        const selectedCountryId = state.selectedCountryIds.find(
          (countryId) => countryId !== country.id
        );
        if (selectedCountryId) {
          dispatch("selectCountry", { countryId: selectedCountryId });
        }
      }
      commit("UPDATE_CHANNEL_SELECTOR_COUNTRY", {
        countryId: country?.id,
        selected: false,
      });
    },
    async selectAllChannelByCountry({ commit, dispatch, state }) {
      const notSelectedChannelIds = state.channelsBySelectedCountryId
        .filter((channelId) => !state.selectedChannelIds.includes(channelId))
        .map(({ id }) => id);
      if (notSelectedChannelIds?.length > 0) {
        commit("UPDATE_SELECTED_CHANNELS", {
          selectedChannelIds: [
            ...new Set([...state.selectedChannelIds, ...notSelectedChannelIds]),
          ],
        });
        commit("UPDATE_CHANNELS", {
          channels: state.channelsBySelectedCountryId.map((channel) => {
            if (!notSelectedChannelIds.includes(channel?.id)) {
              return channel;
            }
            return {
              ...channel,
              selected: true,
            };
          }),
        });
        dispatch("syncChannels", {
          add: notSelectedChannelIds,
        });
      }
    },
    async deselectAllChannelByCountry({ commit, dispatch, state }) {
      const selectedChannelIds = state.channelsBySelectedCountryId.map(
        ({ id }) => id
      );
      commit("UPDATE_SELECTED_CHANNELS", {
        selectedChannelIds: [
          ...state.selectedChannelIds.filter(
            (channelId) => !selectedChannelIds.includes(channelId)
          ),
        ],
      });
      commit("UPDATE_CHANNELS", {
        channels: state.channelsBySelectedCountryId.map((channel) => {
          return {
            ...channel,
            selected: false,
          };
        }),
      });
      dispatch("syncChannels", {
        remove: selectedChannelIds,
      });
    },
    async revertDeletedChannels({ dispatch }) {
      await dispatch("revertChanges", { onlyDeleted: true });
    },
    async revertChanges(
      { commit, state, dispatch },
      { onlyDeleted = undefined }
    ) {
      commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", true);
      try {
        const {
          data: { data: cart },
        } = await deletePendingSelectedChannels({
          cartToken: state?.cart?.cartToken,
          onlyDeleted,
        });
        await dispatch("refreshCartData", { cart });
      } catch (e) {
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", false);
      }
    },
    async addChannel({ state, commit, dispatch }, { channelId }) {
      commit("UPDATE_SELECTED_CHANNELS", {
        selectedChannelIds: [
          ...new Set([channelId, ...state.selectedChannelIds]),
        ],
      });
      commit("UPDATE_CHANNELS", {
        channels: state.channelsBySelectedCountryId.map((channel) => {
          if (channel.id !== channelId) {
            return channel;
          }
          return {
            ...channel,
            selected: true,
          };
        }),
      });
      dispatch("syncChannels", {
        add: [channelId],
      });
    },
    async removeChannel({ commit, dispatch, state }, { channelId }) {
      commit("UPDATE_SELECTED_CHANNELS", {
        selectedChannelIds: [
          ...new Set([
            ...state.selectedChannelIds.filter((id) => id !== channelId),
          ]),
        ],
      });
      commit("UPDATE_CHANNELS", {
        channels: state.channelsBySelectedCountryId.map((channel) => {
          if (channel.id !== channelId) {
            return channel;
          }
          return {
            ...channel,
            selected: false,
          };
        }),
      });
      dispatch("syncChannels", {
        remove: [channelId],
      });
    },
    async saveChannelModifications({ commit, dispatch, state }, trial) {
      commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", true);
      try {
        const response = await postSelectedChannels({
          cartToken: state.cart.cartToken,
          trial,
        });
        const redirectTo =
          response?.data?.redirect_to ||
          response?.data?.redirectTo ||
          response?.data?.data?.redirect_to ||
          response?.data?.data?.redirectTo;
        if (redirectTo) {
          router.push({ name: redirectTo });
        } else {
          dispatch("fetchCart");
        }
      } catch (e) {
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_CHANNEL_SELECTOR_SYNC_LOADING", false);
      }
    },
  },
};
