<template>
  <div
    class="d-flex justify-content-between onboard-header mobile-onboard-light-header mobile-onboard-dark-header"
  >
    <img :src="appLogoImage" class="app-logo" />
    <button
      class="btn text-success skip-button mt-auto mb-auto"
      @click="$emit('skip')"
    >
      Skip
    </button>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
export default {
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>

<style scoped>
.app-logo {
  width: 100px;
  margin-top: 20px;
}
.onboard-header {
  position: absolute;
  z-index: 2;
  width: 58% !important;
  padding-right: 1em;
  margin-left: 2em;
  margin-right: 2em;
}
@media (max-width: 779px) {
  .mobile-onboard-light-header {
    margin-left: 0em;
    margin-right: 0em;
    padding-left: 2em;
    padding-bottom: 0.5em;
    padding-right: 0;
    border-radius: 7px 7px 0 0;
    width: 100% !important;
    background: white;
  }
  .dark-layout .mobile-onboard-dark-header {
    margin-left: 0em;
    margin-right: 0em;
    padding-left: 2em;
    padding-bottom: 0.5em;
    padding-right: 0;
    border-radius: 7px 7px 0 0;
    width: 100% !important;
    background: #283046;
  }
}
</style>
