import {
  cancelSubscription,
  fetchProfileSubscriptions,
} from "@/services/api/auth";
import { apiErrorHandler } from "@/services/utils/util";
import Vue from "vue";

const initState = {
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};

export default {
  namespaced: true,
  state: initState,
  getters: {
    getUserSubscriptionData: (state) => state.data ?? [],
    getUserSubscriptionMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    CLEAR(state) {
      state.data = initState.data;
      state.meta = initState.meta;
    },
    UPDATE_USER_SUBSCRIPTIONS(
      state,
      { data = initState.data, meta = initState.meta }
    ) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async cancelSubscription({ commit, dispatch }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        await cancelSubscription();
        await dispatch("getUserSubscriptions", {});

        Vue.$toast.success("Subscription renewal canceled successfully");
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async getUserSubscriptions({ commit, state }, { page, perPage, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchProfileSubscriptions({
          page: page ?? state?.meta?.current_page,
          per_page: perPage ?? state?.meta?.per_page,
          ...rest,
        });
        const { data, meta } = response?.data || {};
        commit("UPDATE_USER_SUBSCRIPTIONS", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
