<template>
  <footer :class="!isFirstStep && !isMobileScreen && 'footer'">
    <div v-if="isMobileScreen" class="step-dots">
      <div
        class="step-dot"
        v-for="n in max"
        :class="{ active: n == step }"
        :key="n.index"
        @click="$emit('goToStep', n)"
      ></div>
    </div>
    <div class="forward-actions">
      <button
        class="btn btn-primary"
        :class="isMobileScreen && 'mobile-button'"
        v-if="!isLastStep"
        @click="$emit('next', 1)"
      >
        Next
      </button>
      <button
        class="btn btn-primary"
        :class="isMobileScreen && 'mobile-button'"
        :disabled="!isLastStep"
        v-if="isLastStep"
        @click="$emit('finish')"
      >
        Go to XMLTV
      </button>
    </div>
    <div v-if="!isMobileScreen" class="step-dots">
      <div
        class="step-dot"
        v-for="n in max"
        :class="{ active: n == step }"
        :key="n.index"
        @click="$emit('goToStep', n)"
      ></div>
    </div>
    <div class="back-actions">
      <button class="btn" v-if="!isFirstStep" @click="$emit('next', -1)">
        Back
      </button>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
    },
    max: {
      type: Number,
    },
    isLastStep: {
      type: Boolean,
    },
    isFirstStep: {
      type: Boolean,
    },
    isMobileScreen: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  width: 58%;
  margin-left: 0px !important;
  padding: 1em;
}
footer {
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: auto;
  padding: 2em;
  margin-left: 0px !important;
}
@media (max-width: 779px) {
  footer {
    flex-direction: column;
  }
}
.step-dots {
  display: flex;
  flex-direction: var(--axis, row);
}
.step-dot {
  cursor: pointer;
  width: 0.7em;
  height: 0.7em;
  margin: 0.5ch;
  border-radius: 1em;
  background: black;
  opacity: 0.2;
  transition: transform 100ms ease-out, opacity 150ms linear;
  &.active {
    opacity: 0.7;
    box-shadow: 0 0 1em -0.25em;
    background: #28c76f;
  }
  &:hover {
    transform: scale(1.2);
  }
}
.forward-actions,
.back-actions {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.forward-actions {
  justify-content: flex-end;
}
.back-actions {
  justify-content: flex-start;
}
.onboarding-mobile-footer {
  min-width: 100% !important;
}
.mobile-button {
  position: relative;
  width: 85vw;
  margin-top: 15px;
}
</style>
