import axios from "@axios";

//TODO outsource ip api functions to /services/api/ip.js-
export const fetchIps = async (params = {}) => {
  return await axios.get("/admin/ip-address", { params });
};

export const fetchIP = async (ip) => {
  const result = await axios.get(`/admin/ip-address/${ip}`);
  return result.data?.data;
};

export const postBannedIp = async (ip, params = {}) => {
  const result = await axios.post(`/admin/ip-address/${ip}/ban`, params);
  return result.data?.data;
};

export const deleteBannedIp = async (ip) => {
  const result = await axios.delete(`/admin/ip-address/${ip}/ban`);
  return result.data?.data;
};

export const postWhitelistIp = async (ip, params = {}) => {
  const result = await axios.post(`/admin/ip-address/${ip}/whitelist`, params);
  return result.data?.data;
};

export const deleteFromWhitelist = async (ip) => {
  const result = await axios.delete(`/admin/ip-address/${ip}/whitelist`);
  return result.data?.data;
};

export const fetchUsers = async (params = {}) => {
  return await axios.get("/admin/user", { params });
};

export const fetchUser = async (id) => {
  const result = await axios.get(`/admin/user/${id}`);
  return result.data?.data;
};

export const putUser = async (id, params = {}) => {
  return await axios.put(`/admin/user/${id}`, params);
};

export const banUserToggle = async (id, params) => {
  return await axios.put(`/admin/user/${id}/ban`, params);
};

export const inactivateUserToggle = async (id, params) => {
  return await axios.put(`/admin/user/${id}/inactivate`, params);
};

export const postLoginAsUser = async (id) => {
  return await axios.post(`/admin/user/${id}/login-as`);
};

export const postVerifyEmail = async (id) => {
  return await axios.post(`/admin/user/${id}/email-verify`);
};

export const postForgetPassword = async (id) => {
  return await axios.post(`/admin/user/${id}/forget-password`);
};

export const postResendVerifyEmail = async (id) => {
  return await axios.post(`/admin/user/${id}/resend-email-verify`);
};

export const fetchOrders = async (params = {}) => {
  return await axios.get("/admin/order", { params });
};

export const fetchTotalIncome = async (params = {}) => {
  return await axios.get("/admin/order/total-income", { params });
};

export const fetchPayments = async (params = {}) => {
  return await axios.get("/admin/payment", { params });
};

export const fetchPayment = async (id) => {
  const result = await axios.get(`/admin/payment/${id}`);
  return result.data?.data;
};

export const fetchPaymentTotalIncome = async (params = {}) => {
  return await axios.get("/admin/payment/total-income", { params });
};

export const fetchSubscriptions = async (params = {}) => {
  return await axios.get("/admin/subscription", { params });
};

export const fetchSubscription = async (id, params = {}) => {
  const result = await axios.get(`/admin/subscription/${id}`, params);
  return result.data?.data;
};

export const updateService = async (id, params = {}) => {
  return await axios.put(`/admin/service/${id}`, params);
};

export const fetchSysConfig = async (params = {}) => {
  return await axios.get(`/system-config`, params);
};

export const updateSysConfig = async (id, data = {}) => {
  return await axios.put(`/admin/system-config/${id}`, data);
};

export const fetchEmailTemplates = async (params = {}) => {
  return await axios.get(`/admin/email-template`, params);
};

export const fetchEmailTemplate = async (id) => {
  const result = await axios.get(`/admin/email-template/${id}`);
  return result.data?.data;
};

export const updateEmailTemplate = async (id, data = {}) => {
  return await axios.put(`/admin/email-template/${id}`, data);
};

export const postFaq = async (data = {}) => {
  return await axios.post(`/admin/faq`, data);
};

export const updateFaq = async (id, data = {}) => {
  return await axios.put(`/admin/faq/${id}`, data);
};

export const deleteFaq = async (ip) => {
  const result = await axios.delete(`/admin/faq/${ip}`);
  return result.data?.data;
};

export const postEpgLink = async (id) => {
  return await axios.post(`/admin/user/${id}/epg`);
};

export const deleteChannel = async (id) => {
  const result = await axios.delete(`/admin/channel/${id}`);
  return result.data?.data;
};

export const massDeleteChannels = async (channel_ids = {}) => {
  return await axios.post("/admin/channel-mass-delete", { channel_ids });
};
