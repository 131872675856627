import axios from "@/libs/axios";

export const getStaticContents = async () => {
  const response = await axios.get(`/static-content`);
  return response?.data?.data || {};
};

export const putStaticContent = async (code, content) => {
  const response = await axios.put(`admin/static-content/${code}`, { content });
  return response?.data?.data;
};
