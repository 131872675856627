import { fetchServices } from "@/services/api/services";
import { updateService } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";
import Vue from "vue";

const initState = {
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};
export default {
  namespaced: true,
  state: initState,
  getters: {
    getServicesListData: (state) => state.data,
    getServicesListMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    CLEAR(state) {
      state.data = initState.data;
      state.meta = initState.meta;
    },
    UPDATE_SERVICES(state, { data = initState.data, meta = initState.meta }) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_SERVICE(state, { service: updatedService }) {
      state.data = state.data.map((service) => {
        return service.id === updatedService.id ? updatedService : service;
      });
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getServices({ commit }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchServices();
        const { data, meta } = response?.data || {};
        commit("UPDATE_SERVICES", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async updateService({ commit }, { id, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await updateService(id, rest);
        const { data } = response?.data || {};
        commit("UPDATE_SERVICE", { service: data });
        Vue.$toast.success("Service updated successfully");
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
