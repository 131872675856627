import { fetchEmailTemplates, updateEmailTemplate } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";
import Vue from "vue";

const initState = {
  data: [],
  loading: false,
  error: false,
};

export default {
  namespaced: true,
  state: initState,
  getters: {
    getEmailTemplateData: (state) => state.data,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    UPDATE_EMAIL_TEMPLATES(state, { data = initState.data }) {
      state.data = data;
      state.errors = null;
    },
    UPDATE_EMAIL_TEMPLATE(state, { data: updatedEmailTemplate }) {
      state.data = state.data.map((emailTemplate) => {
        return emailTemplate.id === updatedEmailTemplate.id
          ? updatedEmailTemplate
          : emailTemplate;
      });
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getEmailTemplate({ commit }, params) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchEmailTemplates({ params });
        const { data } = response?.data || {};
        commit("UPDATE_EMAIL_TEMPLATES", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async putEmailTemplate({ commit }, { id, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await updateEmailTemplate(id, rest);
        const { data } = response?.data || {};
        commit("UPDATE_EMAIL_TEMPLATE", { data });
        Vue.$toast.success("Email template updated successfully");
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
