<template>
  <div class="left-content pl-2 pr-2 mt-5 pt-2 mobile-onboard-content">
    <h1 class="mb-2 mt-2 font-weight-bolder">{{ sectionTitle }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    sectionTitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.left-content {
  width: 60%;
  position: relative;
  padding-top: 1em;
}
@media (max-width: 779px) {
  .mobile-onboard-content {
    width: 100%;
  }
}
</style>
