import { fetchSysConfig, updateSysConfig } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";

const initState = {
  data: [],
  loading: false,
  error: false,
};

export default {
  namespaced: true,
  state: initState,
  getters: {
    getSysConfigValue: (state) => (code) => {
      const value = state.data?.find((data) => data.code === code)?.value;
      return parseFloat(value);
    },
    getSysConfigData: (state) => state.data,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    UPDATE_CONFIGS(state, { data = initState.data }) {
      state.data = data;
      state.errors = null;
    },
    UPDATE_CONFIG(state, { data: updatedConfig }) {
      state.data = state.data.map((config) => {
        return config.id === updatedConfig.id ? updatedConfig : config;
      });
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getSysConfig({ commit }, params) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchSysConfig({ params });
        const { data } = response?.data || {};
        commit("UPDATE_CONFIGS", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async putConfig({ commit }, { id, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await updateSysConfig(id, rest);
        const { data } = response?.data || {};
        commit("UPDATE_CONFIG", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
