import debounce from "lodash.debounce";
import { putSelectedChannels } from "@/services/api/cart";

const SYNC_DEBOUNCE_TIME = 1000; // 1s
const SYNC_CHANNEL_PREFIX = "@sync-channel: ";

let diffChannels = {
  add: [],
  remove: [],
};

const sendChannelDiffs = debounce(async (resolve, cartToken) => {
  const payload = { ...diffChannels, cartToken };
  console.log(
    `${SYNC_CHANNEL_PREFIX} Send New: ${diffChannels.add.length}, Remove: ${diffChannels.remove.length}`
  );
  diffChannels = {
    add: [],
    remove: [],
  };
  try {
    const response = await putSelectedChannels(payload);
    resolve(response);
  } catch (e) {
    await syncStoreChannels(payload, cartToken);
  }
}, SYNC_DEBOUNCE_TIME);

const mergeChannelDiff = ({ add = [], remove = [] }) => {
  diffChannels = {
    add: [
      ...new Set([
        ...diffChannels.add.filter((id) => !remove.includes(id)),
        ...add,
      ]),
    ],
    remove: [
      ...new Set([
        ...diffChannels.remove.filter((id) => !add.includes(id)),
        ...remove,
      ]),
    ],
  };
  console.log(
    `${SYNC_CHANNEL_PREFIX} Merge New: ${diffChannels.add.length}, Remove: ${diffChannels.remove.length}`
  );
};

export const syncStoreChannels = async (
  { add = [], remove = [] },
  cartToken
) => {
  mergeChannelDiff({ add, remove });
  return new Promise((resolve) => sendChannelDiffs(resolve, cartToken));
};
