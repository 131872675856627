const formatDate = (date, defaultResponse = "") => {
  const year = new Date(date).getFullYear();
  const stringMonth = new Date(date).toLocaleString("default", {
    month: "short",
  });
  const day = new Date(date).getDate();

  return date ? `${year}-${stringMonth}-${day}` : defaultResponse;
};

const addDays = (date, days = 1) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const formatTime = (date, defaultResponse = "") => {
  return date ? new Date(date).toLocaleTimeString() : defaultResponse;
};

export default { formatDate, addDays, formatTime };
