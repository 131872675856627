import {
  getStaticContents,
  putStaticContent,
} from "@/services/api/staticContent";
import {
  apiErrorHandler,
  getObjectFromLocalStorage,
} from "@/services/utils/util";

export const STATIC_CONTENTS_KEY = "STATIC_CONTENTS_KEY";

const getInitState = () => {
  try {
    return {
      contents: getObjectFromLocalStorage(STATIC_CONTENTS_KEY) ?? [],
      loading: false,
      errors: null,
    };
  } catch (e) {
    return {
      contents: [],
      loading: false,
      errors: null,
    };
  }
};
export default {
  namespaced: true,
  state: getInitState(),
  getters: {
    getContents: (state) => state.contents,
    isLoading: (state) => state.loading,
    error: (state) => state.errors,
  },
  mutations: {
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
    UPDATE_CONTENTS(state, contents) {
      state.contents = contents;
      localStorage.setItem(STATIC_CONTENTS_KEY, JSON.stringify(state.contents));
    },
    UPDATE_CONTENT(state, updatedStaticContent) {
      state.contents = state.contents.map((staticContent) =>
        staticContent?.code === updatedStaticContent?.code
          ? updatedStaticContent
          : staticContent
      );
      localStorage.setItem(STATIC_CONTENTS_KEY, JSON.stringify(state.contents));
    },
  },
  actions: {
    async updateStaticContent({ commit }, { code, content }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const staticContent = await putStaticContent(code, content);
        console.log("updateStaticContent", { staticContent });
        commit("UPDATE_CONTENT", staticContent);
      } catch (e) {
        console.error(e);
        commit("UPDATE_ERROR", e.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async initStaticContent({ commit }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await getStaticContents();
        commit("UPDATE_CONTENTS", response);
      } catch (e) {
        console.error(e);
        commit("UPDATE_ERROR", e.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
