import { fetchOrders, fetchTotalIncome } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";

const initState = {
  totals: [],
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};
export default {
  namespaced: true,
  state: initState,
  getters: {
    getTotalIncomeData: (state) => state.totals,
    getOrderListData: (state) => state.data,
    getOrderListMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    CLEAR(state) {
      state.data = initState.data;
      state.meta = initState.meta;
      state.totals = initState.totals;
    },
    UPDATE_TOTAL(state, { totals = initState.totals }) {
      state.totals = totals;
    },
    UPDATE_ORDERS(state, { data = initState.data, meta = initState.meta }) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getOrders({ commit, state }, { page, perPage, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchOrders({
          page: page ?? state?.meta?.current_page,
          per_page: perPage ?? state?.meta?.per_page,
          ...rest,
        });
        const { data, meta } = response?.data || {};
        commit("UPDATE_ORDERS", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async getTotalIncome({ commit }, datas) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchTotalIncome(datas);
        const totals = response?.data;
        commit("UPDATE_TOTAL", { totals });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
