import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import { postLoginAsUser } from "@/services/api/admin";
import {
  getProfile,
  postForgetPassword,
  postResetPassword,
  postUpdatePassword,
  putProfile,
  socialAccountConnect,
  socialAuth,
  postResendEmailVerify,
  postLogout,
} from "@/services/api/auth";
import {
  apiErrorHandler,
  getObjectFromLocalStorage,
} from "@/services/utils/util";
import Vue from "vue";

const LogoutRedirect = "channels";

export default {
  namespaced: true,
  state: {
    user: getObjectFromLocalStorage("user"),
    loading: false,
    errors: null,
  },
  getters: {
    authUser: (state) => state.user,
    isAdmin: (state) => !!state.user?.is_admin,
    authError: (state) => state.errors,
    isAuthLoading: (state) => state.loading,
    activeUntil: (state) => state.user?.services?.[0]?.finish_at,
  },
  mutations: {
    UPDATE_AUTH_USER(state, user) {
      state.user = user;
      state.errors = null;
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_AUTH_ERROR(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async logout({ commit, dispatch }) {
      await postLogout().catch(console.error);
      useJwt.setToken("");
      localStorage.removeItem("user");
      commit("UPDATE_AUTH_USER", null);
      commit("adminUser/CLEAR", {}, { root: true });
      dispatch("channelSelector/clearStore", {}, { root: true });
      if (router.history?.current?.name !== LogoutRedirect) {
        await router.replace({ name: LogoutRedirect });
      }
    },
    async updateProfile({ commit }, userData) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await putProfile(userData);
        const { data } = response?.data || {};
        localStorage.setItem("user", JSON.stringify(data));
        commit("UPDATE_AUTH_USER", data);
        Vue.$toast.success("Profile updated successfully");
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async forgotPassword({ commit }, credentials) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        await postForgetPassword(credentials);
        Vue.$toast.success("Reset password link sent");
        router.replace({ name: "login" });
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async updatePassword({ commit }, credentials) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        await postUpdatePassword(credentials);
        Vue.$toast.success("Password updated successfully");
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
        throw e;
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async resetPassword({ commit }, credentials) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        await postResetPassword(credentials);
        Vue.$toast.success("Password reset successfully");
        router.replace({ name: "login" });
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },

    async connectSocialAccount({ commit }, { provider, data }) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await socialAccountConnect(provider, data);
        const { data: user } = response?.data || {};
        localStorage.setItem("user", JSON.stringify(user));
        commit("UPDATE_AUTH_USER", user);
      } catch (e) {
        //dispatch("logout");
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },

    async refreshProfile({ commit, dispatch }) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      if (!localStorage.getItem("user")) {
        commit("UPDATE_LOADING", false);
      } else {
        try {
          const response = await getProfile();
          const { data: user } = response?.data || {};
          localStorage.setItem("user", JSON.stringify(user));
          commit("UPDATE_AUTH_USER", user);
        } catch (e) {
          commit("UPDATE_AUTH_ERROR", e.response?.data?.message);
          dispatch("logout");
          Vue.$toast.error("Your session has expired. Please log in.");
        } finally {
          commit("UPDATE_LOADING", false);
        }
      }
    },
    async initAuthStore({ dispatch }) {
      dispatch("refreshProfile");
    },
    async successLogin({ commit, dispatch }, { user, token }) {
      useJwt.setToken(token);
      localStorage.setItem("user", JSON.stringify(user));
      commit("UPDATE_AUTH_USER", user);
      dispatch("channelSelector/initializeChannelSelector", {}, { root: true });
      router.replace({ name: "channels" });
    },
    async socialLogin({ commit, dispatch }, { provider, data }) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await socialAuth(provider, data);
        const { token, ...user } = response?.data?.data;
        dispatch("successLogin", { token, user });
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async login({ commit, dispatch, rootState }, credentials) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await useJwt.login({
          ...credentials,
          cartToken: rootState.channelSelector.cartInfo?.cartToken,
        });
        const { token, ...user } = response?.data?.data;
        dispatch("successLogin", { token, user });
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },

    async loginAs({ commit, dispatch }, loginAsUser) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await postLoginAsUser(loginAsUser.id);
        const { token, ...user } = response?.data?.data;
        dispatch("successLogin", { token, user });
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async resendEmailVerify({ commit }, credentials) {
      commit("UPDATE_AUTH_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        await postResendEmailVerify(credentials);
        Vue.$toast.success("Verification email sent");
        router.replace({ name: "login" });
      } catch (e) {
        commit("UPDATE_AUTH_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
