<template>
  <transition name="onboardingBody">
    <div class="onboarding-body dark-wrapper">
      <div class="onboarding-body__mask"></div>
      <div class="onboarding-body__wrapper onboarding-mobile">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.onboarding-body {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__mask {
    backdrop-filter: blur(4px);
    background: rgba(#ffffff, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  &__wrapper {
    position: relative;
    width: 70vw;
    max-width: 70em;
    min-width: 55em;
    max-height: 70vh;
    min-height: 480px;
    height: 38em;
    background: #fff;
    color: #333;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }
}
@media (max-width: 779px) {
  .onboarding-mobile {
    min-width: 95%;
    min-height: 95vh;
  }
}
.dark-layout .dark-wrapper .onboarding-body__wrapper {
  background: #283046 !important;
  color: #d0d2d6 !important;
  box-shadow: 0 5px 50px 0 rgba(255, 255, 255, 0.15);
}
.dark-layout .dark-wrapper .onboarding-body__mask {
  background: rgba(#000000, 0.65);
}
</style>
