import { fetchFaqs } from "@/services/api/faq";
import { updateFaq, postFaq } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";
import Vue from "vue";

const initState = {
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};
export default {
  namespaced: true,
  state: initState,
  getters: {
    getFaqListData: (state) => state.data,
    getFaqListMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    CLEAR(state) {
      state.data = initState.data;
      state.meta = initState.meta;
    },
    ADD_FAQ(state, newFaq) {
      state.data = [...state.data, newFaq];
      state.errors = null;
    },
    UPDATE_FAQS(state, { data = initState.data, meta = initState.meta }) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_FAQ(state, { faq: updatedFaq }) {
      state.data = state.data.map((faq) => {
        return faq.id === updatedFaq.id ? updatedFaq : faq;
      });
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getFaqs({ commit, state }, { page, perPage, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchFaqs({
          page: page ?? state?.meta?.current_page,
          per_page: perPage ?? state?.meta?.per_page,
          ...rest,
        });
        const { data, meta } = response?.data || {};
        commit("UPDATE_FAQS", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async updateFaq({ commit }, { id, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await updateFaq(id, rest);
        const { data } = response?.data || {};
        commit("UPDATE_FAQ", { faq: data });
        Vue.$toast.success("FAQ updated successfully");
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async createFaq({ commit }, { ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await postFaq(rest);
        const { data } = response?.data || {};
        commit("ADD_FAQ", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
