import Vue from "vue";
import { setupCache } from "axios-cache-adapter";
// axios
import axios from "axios";
import localforage from "localforage";

console.info(`api:base-url: ${process.env.VUE_APP_API_URL}`);
const CACHE_TIME = 60 * 60 * 1000; // 1 hour
const CACHE_KEY = "api:cache:xml-tv-source";
const CACHE_URL = ["country", "channel"];

const storageInfo = localforage.createInstance({
  driver: [localforage.LOCALSTORAGE],
  name: CACHE_KEY,
});

export const axiosCache = setupCache({
  maxAge: CACHE_TIME,
  store: storageInfo,
  exclude: {
    filter: (request) => {
      const exclude = !CACHE_URL.includes(request.url) || !request?.params?.all;
      console.info(
        `${CACHE_KEY} filter '${request.url}': ${!exclude ? "true" : "false"}`
      );
      return exclude;
    },
    query: false,
  },
  clearOnStale: true,
});

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  adapter: axiosCache.adapter,
});

Vue.prototype.$http = axiosIns;

export const axiosDisableCacheConfig = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export default axiosIns;
