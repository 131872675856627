import Vue from "vue";
import VueSocialAuth from "vue-social-auth";

const options = {
  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: window.location.origin + "/login", // Your client app URL
    },
    paypal: {
      clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
      redirectUri: window.location.origin + "/login", // Your client app URL
      name: "paypal",
      url: "/auth/paypal",
      authorizationEndpoint:
        process.env.VUE_APP_PAYPAL_MODE === "live"
          ? "https://www.paypal.com/idapps/connect/consent"
          : "https://www.sandbox.paypal.com/idapps/connect/consent",
      //
      requiredUrlParams: ["display", "scope"],
      defaultUrlParams: ["response_type", "client_id", "redirect_uri"],
      scope: ["openid", "profile", "email"],
      responseType: "code",
      scopeDelimiter: " ",
      display: "popup",
      oauthType: "2.0",
      popupOptions: { width: 580, height: 400 },
    },
  },
};

console.log({ options });
Vue.use(VueSocialAuth, options);
