import axios, { axiosDisableCacheConfig } from "@/libs/axios";

export const postCheckout = async () => {
  return await axios.post("checkout");
};

export const putSelectedChannels = async ({
  cartToken = undefined,
  add = [],
  remove = [],
}) => {
  return await axios.put("channel/select", { cartToken, add, remove });
};

export const fetchSelectedChannels = async ({ cartToken = undefined } = {}) => {
  return await axios.get("channel/select", {
    params: { cartToken },
    ...axiosDisableCacheConfig,
  });
};

export const postSelectedChannels = async ({ trial } = {}) => {
  return await axios.post("channel/select", {
    is_trial: trial,
  });
};

export const checkSubscription = async (params = {}) => {
  return await axios.post("checkout/callback", params);
};

export const deletePendingSelectedChannels = async ({
  cartToken = undefined,
  onlyDeleted = undefined,
}) => {
  return await axios.delete("channel/select", {
    params: {
      cartToken,
      onlyDeleted,
    },
  });
};
