import { fetchIps, postBannedIp, postWhitelistIp } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";

const initState = {
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};
export default {
  namespaced: true,
  state: initState,
  getters: {
    getBannedListData: (state) => state.data,
    getBannedListMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    ADD_IP_BAN(state, newIpBan) {
      state.data = [...state.data, newIpBan];
      state.errors = null;
    },
    ADD_WHITELIST_IP(state, newWhitelistIp) {
      state.data = [...state.data, newWhitelistIp];
      state.errors = null;
    },
    UPDATE_BANNED_IPS(state, { data = initState.data, meta = initState.meta }) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getBannedIps({ commit, state }, { page, perPage, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchIps({
          page: page ?? state?.meta?.current_page,
          per_page: perPage ?? state?.meta?.per_page,
          ...rest,
        });
        const { data, meta } = response?.data || {};
        commit("UPDATE_BANNED_IPS", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async createBan({ commit }, { ip, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await postBannedIp(ip, rest);
        const { data } = response?.data || {};
        commit("ADD_IP_BAN", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async createWhitelisted({ commit }, { ip, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await postWhitelistIp(ip, rest);
        const { data } = response?.data || {};
        commit("ADD_WHITELIST_IP", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
