import { fetchCountries, putCountry } from "@/services/api/country";
import { axiosCache } from "@/libs/axios";
import { apiErrorHandler } from "@/services/utils/util";

const initState = {
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};
export default {
  namespaced: true,
  state: initState,
  getters: {
    getCountryListData: (state) => state.data,
    getCountryListMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    CLEAR(state) {
      state.data = initState.data;
      state.meta = initState.meta;
    },
    UPDATE_COUNTIES(state, { data = initState.data, meta = initState.meta }) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_COUNTY(state, { data: updatedCountry }) {
      state.data = state.data.map((country) => {
        return country.id === updatedCountry.id ? updatedCountry : country;
      });
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getCountries({ commit, state }, { page, perPage, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchCountries({
          page: page ?? state?.meta?.current_page,
          per_page: perPage ?? state?.meta?.per_page,
          ...rest,
        });
        const { data, meta } = response?.data || {};
        commit("UPDATE_COUNTIES", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async updateCountry({ commit }, { id, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await putCountry(id, rest);
        const { data } = response?.data || {};
        //todo clear only country?all=true
        axiosCache.store.clear();
        commit("UPDATE_COUNTY", { data });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
