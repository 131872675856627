import axios from "@/libs/axios";

export const getProfile = async () => {
  return await axios.get("/auth/profile");
};

export const postLogout = async () => {
  return await axios.post("/auth/logout");
};

export const putProfile = async (data = {}) => {
  return await axios.put("/auth/profile", data);
};

export const deleteProfile = async () => {
  const result = await axios.delete(`/auth/profile`);
  return result.data?.data;
};

export const postRegistration = async (data = {}) => {
  return await axios.post("/auth/registration", data);
};

export const postResetPassword = async (data = {}) => {
  return await axios.post("/auth/reset-password", data);
};

export const postUpdatePassword = async (data = {}) => {
  return await axios.put("/auth/update-password", data);
};

export const postForgetPassword = async (data = {}) => {
  return await axios.post("/auth/forget-password", data);
};

export const fetchProfileSubscriptions = async (params = {}) => {
  return await axios.get("/subscription", { params });
};

export const cancelSubscription = async () => {
  return await axios.post("/subscription/cancel");
};

export const socialAuth = async (provider, data) => {
  return await axios.post(`/auth/social/${provider}`, data);
};

export const socialAccountConnect = async (provider, data) => {
  return await axios.post(`/auth/social/${provider}/connect`, data);
};

export const postResendEmailVerify = async (data = {}) => {
  return await axios.post("/auth/verify-email-resend", data);
};

export const getUserServicesApi = async (userId) => {
  const result = await axios.get(`/services/user-services/${userId}`);
  return result.data?.data;
};
