<template>
  <onboarding-body v-show="isOpen">
    <article>
      <onboarding-header
        v-if="!isFirstStep"
        @skip="closeModal"
      ></onboarding-header>
      <section class="step text-center mt-1">
        <img
          :src="appLogoImage"
          class="app-logo"
          :class="isMobileScreen && 'app-logo-mobile'"
        />
        <button class="btn text-success skip-button" @click="closeModal">
          Skip
        </button>
        <h1 class="w-100 font-weight-bolder mb-3">Welcome to XMLTV Source!</h1>
        <p class="w-100">
          In XMLTV Source, you can find detailed descriptions of nearly 8000
          IPTV channels from 110 countries.
        </p>
        <p class="w-100">
          We provide the most sophisticated Electronic Program Guide service for
          IPTV.
        </p>
        <p class="w-100">
          You can find currently running programs, their titles, detailed
          descriptions, year of productions, country of origins and much more.
        </p>
        <p>
          Our service is compatible with any service that uses an XMLTV format.
        </p>
        <p>Please click next to continue...</p>
      </section>
      <section class="step">
        <onboarding-text-wrapper :sectionTitle="'Select countries'">
          <p>
            First, you need to select a country which will result in listing all
            the channels that belong to that specific country.
          </p>
          <p>You can select more than one country at a time.</p>
          <p>
            The number in the brackets next to each country's name indicates how
            many channels that country has.
          </p>
        </onboarding-text-wrapper>
        <onboarding-images-wrapper
          :desktopDarkImage="
            require('@/assets/images/onboarding/desktop-dark-1.png')
          "
          :desktopLightImage="
            require('@/assets/images/onboarding/desktop-1.png')
          "
          :mobileDarkImage="
            require('@/assets/images/onboarding/Mobil-dark-1.png')
          "
          :mobileLightImage="
            require('@/assets/images/onboarding/Mobil-light-1.png')
          "
        ></onboarding-images-wrapper>
      </section>
      <section class="step">
        <onboarding-text-wrapper :sectionTitle="'Select channels'">
          <p>
            Second, you need to select channels. You can search for specific
            channels. Add channels one by one or select all of them.
          </p>
          <p>
            If you are logged in, an EPG button will appear next to each
            channel. Clicking it will open a new window, offering you relevant
            information about the currently running program on that channel.
          </p>
          <p>
            Relevant subscription information can be found at the top of the
            page. To subscribe, all you have to do is log in. If you don't have
            an account, create one.
          </p>
        </onboarding-text-wrapper>
        <onboarding-images-wrapper
          :desktopDarkImage="
            require('@/assets/images/onboarding/desktop-dark-2.png')
          "
          :desktopLightImage="
            require('@/assets/images/onboarding/desktop-2.png')
          "
          :mobileDarkImage="
            require('@/assets/images/onboarding/Mobil-dark-2.png')
          "
          :mobileLightImage="
            require('@/assets/images/onboarding/Mobil-light-2.png')
          "
        ></onboarding-images-wrapper>
      </section>
      <section class="step">
        <onboarding-text-wrapper :sectionTitle="'Order'">
          <p>
            Once you are logged in, all you have to do is click the order
            button. On the Checkout page, you will find information about your
            subscription.
          </p>
          <p>
            You can only subscribe via PayPal or Stripe. Subscriptions are
            always for 12 months and are automatically renewed.
          </p>
          <p>You can subscribe to additional channels at any time.</p>
        </onboarding-text-wrapper>
        <onboarding-images-wrapper
          :desktopDarkImage="
            require('@/assets/images/onboarding/desktop-dark-2.png')
          "
          :desktopLightImage="
            require('@/assets/images/onboarding/desktop-2.png')
          "
          :mobileDarkImage="
            require('@/assets/images/onboarding/Mobil-dark-3.png')
          "
          :mobileLightImage="
            require('@/assets/images/onboarding/Mobil-light-3.png')
          "
        ></onboarding-images-wrapper>
      </section>
      <section class="step">
        <onboarding-text-wrapper :sectionTitle="'Get the EPG link'">
          <p>
            After successfully paying for your subscription, the XML link will
            be permanently visible to you in the Channels page and in your
            Profile page.
          </p>
          <p>
            Please note that the EPG XML link will always remain the same, even
            if you delete or update your channel list.
          </p>
        </onboarding-text-wrapper>
        <onboarding-images-wrapper
          :desktopDarkImage="
            require('@/assets/images/onboarding/desktop-dark-3.png')
          "
          :desktopLightImage="
            require('@/assets/images/onboarding/desktop-3.png')
          "
          :mobileDarkImage="
            require('@/assets/images/onboarding/Mobil-dark-4.png')
          "
          :mobileLightImage="
            require('@/assets/images/onboarding/Mobil-light-4.png')
          "
        ></onboarding-images-wrapper>
      </section>
      <section class="step">
        <onboarding-text-wrapper :sectionTitle="`Let's get started`">
          <p>
            There is no technical support for this page at the moment. If you
            have any questions, please visit our
            <b-link to="/faq-list">FAQ</b-link> page.
          </p>
        </onboarding-text-wrapper>
        <onboarding-images-wrapper
          :desktopDarkImage="
            require('@/assets/images/onboarding/desktop-dark-4.png')
          "
          :desktopLightImage="
            require('@/assets/images/onboarding/desktop-light-4.png')
          "
          :mobileDarkImage="
            require('@/assets/images/onboarding/Mobil-dark-5.png')
          "
          :mobileLightImage="
            require('@/assets/images/onboarding/Mobil-light-5.png')
          "
        ></onboarding-images-wrapper>
      </section>
    </article>
    <onboarding-footer-nav
      :isFirstStep="isFirstStep"
      :isLastStep="isLastStep"
      :isMobileScreen="isMobileScreen"
      :step="step"
      :max="sections.length"
      @goToStep="goToStep"
      @next="next"
      @finish="closeModal"
    ></onboarding-footer-nav>
  </onboarding-body>
</template>

<script>
import OnboardingBody from "./OnboardingBody.vue";
import OnboardingHeader from "./OnboardingHeader.vue";
import OnboardingTextWrapper from "./OnboardingTextWrapper.vue";
import OnboardingImagesWrapper from "./OnboardingImagesWrapper.vue";
import OnboardingFooterNav from "./OnboardingFooterNav.vue";
import { $themeConfig } from "@themeConfig";
import { BLink } from "bootstrap-vue";

export default {
  components: {
    OnboardingBody,
    OnboardingHeader,
    OnboardingTextWrapper,
    OnboardingImagesWrapper,
    OnboardingFooterNav,
    BLink,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
  data() {
    return {
      step: 1,
      sections: [],
    };
  },
  mounted() {
    this.$watch(
      () => this.isOpen,
      () => (this.isOpen ? this.openModal() : this.closeModal())
    );
    if (!localStorage.getItem("onboarding")) {
      this.$store.commit("appConfig/UPDATE_HIDE_ONBOARDING", false);
    }
  },
  computed: {
    isOpen() {
      return !this.$store.state.appConfig.layout.onboarding.hidden;
    },
    isMobileScreen() {
      return this.$screen.width < 780;
    },
    isFirstStep() {
      return this.step === 1;
    },
    isLastStep() {
      return this.step === this.sections.length;
    },
  },
  methods: {
    openModal() {
      this.sections = this.$el?.querySelectorAll?.("section") ?? [];
      this.goToStep(1);
    },
    closeModal() {
      localStorage.setItem("onboarding", "done");
      this.$store.commit("appConfig/UPDATE_HIDE_ONBOARDING", true);
    },
    goToStep(step) {
      const maxStep = this.sections.length;
      if (maxStep === 0) {
        this.closeModal();
        return;
      }
      this.step = Math.min(maxStep, Math.max(step, 1));
      let currentSection = this.sections[this.step - 1];
      this.sections.forEach((section) => {
        section.classList.remove("current");
      });
      currentSection.classList.add("current");
      this.$el.style.setProperty("--x", (this.step * 100 - 100) * -1 + "%");
    },
    next(step) {
      this.goToStep(this.step + step);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-logo {
  width: 22%;
  margin-bottom: 45px;
  margin-top: 30px;
}

.app-logo-mobile {
  width: 50%;
}

.skip-button {
  position: absolute;
  right: 0;
}

article {
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  .step {
    width: 100%;
    flex: 0 0 100%;
    overflow: auto;
    position: relative;
    will-change: transform;
    transform: translate(var(--x, 0%));
    transition: transform 300ms ease-out;
  }
  .step:not(:first-child) {
    display: flex;
    flex-wrap: wrap;
  }
}

section {
  transition: opacity 300ms;
  opacity: 0;
  &.current {
    opacity: 1;
  }
}
</style>
