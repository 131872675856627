import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import channelSelector from "./channel-selector";
import adminUser from "./admin/user";
import adminCountry from "./admin/country";
import adminOrder from "./admin/order";
import staticContents from "./static-content";
import actualProgram from "./actual-program";
import adminBanned from "./admin/banned";
import adminChannelList from "./admin/channel-list";
import adminPaymentList from "./admin/payment";
import authUserSubscription from "./auth/user-subscription";
import adminSubscriptionList from "./admin/subscription";
import services from "./services";
import adminSystemConfig from "./admin/system-config";
import adminEmailTemplate from "./admin/email-template";
import faq from "./faq";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    app,
    auth,
    appConfig,
    verticalMenu,
    channelSelector,
    adminUser,
    adminCountry,
    adminOrder,
    staticContents,
    actualProgram,
    adminBanned,
    adminChannelList,
    adminPaymentList,
    authUserSubscription,
    adminSubscriptionList,
    services,
    adminSystemConfig,
    adminEmailTemplate,
    faq,
  },
  strict: process.env.DEV,
});
