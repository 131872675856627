import { massDeleteChannels } from "@/services/api/admin";
import { fetchChannels, updateChannel } from "@/services/api/channels";
import { apiErrorHandler } from "@/services/utils/util";
import Vue from "vue";

const initState = {
  data: [],
  meta: {
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  loading: false,
  error: false,
};

export default {
  namespaced: true,
  state: initState,
  getters: {
    getChannelListData: (state) => state.data,
    getChannelListMeta: (state) => state.meta,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    UPDATE_CHANNEL_LIST(
      state,
      { data = initState.data, meta = initState.meta }
    ) {
      state.data = data;
      state.meta = meta;
      state.errors = null;
    },
    UPDATE_CHANNEL(state, { data: updatedChannel }) {
      state.data = state.data.map((channel) => {
        return channel.id === updatedChannel.id ? updatedChannel : channel;
      });
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getChannelList({ commit, state }, { page, perPage, ...rest }) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      try {
        const response = await fetchChannels({
          page: page ?? state?.meta?.current_page,
          per_page: perPage ?? state?.meta?.per_page,
          ...rest,
        });
        const { data, meta } = response?.data || {};
        commit("UPDATE_CHANNEL_LIST", { data, meta });
      } catch (e) {
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
    async updateChannel({ commit }, { id, ...rest }) {
      commit("UPDATE_ERROR", null);
      try {
        const response = await updateChannel(id, rest);
        const { data } = response?.data || {};
        commit("UPDATE_CHANNEL", { data });
        Vue.$toast.success("Channel updated successfully");
      } catch (e) {
        console.log(e);
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      }
    },
    async massDeleteChannels({ commit }, { ...ids }) {
      commit("UPDATE_ERROR", null);
      try {
        await massDeleteChannels(ids);
        Vue.$toast.success("Channels deleted successfully");
      } catch (e) {
        console.log(e);
        commit("UPDATE_ERROR", e?.response?.data?.message);
        apiErrorHandler(e);
      }
    },
  },
};
