import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import { Plugin } from "vue-fragment";
import VueCompositionAPI from "@vue/composition-api";
import "@/libs/tour";
import "@/libs/sweet-alerts";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import helpers from "./services/helpers";
import { VBTooltipPlugin } from "bootstrap-vue";
import VueScreen from "vue-screen";
import VueGtag from "vue-gtag";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/socialAuth";
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VBTooltipPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(Plugin);
Vue.use(VueScreen);

Vue.use(helpers);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

if (process.env.VUE_APP_GTAG_ID) {
  Vue.use(
    VueGtag,
    {
      appName: "XMLTV Source",
      pageTrackerScreenviewEnabled: true,
      config: { id: process.env.VUE_APP_GTAG_ID },
    },
    router
  );
}

if (process.env.VUE_APP_SENTRY_DNS) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
