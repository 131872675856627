import { fetchActualPrograms } from "@/services/api/actualProgram";
import { apiErrorHandler } from "@/services/utils/util";

const initState = {
  data: [],
  loading: false,
  error: false,
};

export default {
  namespaced: true,
  state: initState,
  getters: {
    getActualProgramData: (state) => state.data,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    CLEAR(state) {
      state.data = initState.data;
    },
    UPDATE_ACTUAL_PROGRAM(state, { data = initState.data }) {
      state.data = data;
      state.errors = null;
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getPrograms({ commit }, id) {
      commit("UPDATE_ERROR", null);
      commit("UPDATE_LOADING", true);
      commit("UPDATE_ACTUAL_PROGRAM", { data: null });
      try {
        const response = await fetchActualPrograms(id);
        const { data } = response?.data || {};
        commit("UPDATE_ACTUAL_PROGRAM", { data });
      } catch (e) {
        const error = e?.response?.data?.message;
        commit("UPDATE_ERROR", error);
        apiErrorHandler(e);
      } finally {
        commit("UPDATE_LOADING", false);
      }
    },
  },
};
