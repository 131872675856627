<template>
  <div class="right-content" v-if="!isMobileScreen">
    <img
      v-if="isThemeDark"
      :src="desktopDarkImage"
      class="right-content-image"
    />
    <img v-else :src="desktopLightImage" class="right-content-image" />
  </div>
  <div v-else>
    <img v-if="isThemeDark" :src="mobileDarkImage" class="mobile-img" />
    <img v-else :src="mobileLightImage" class="mobile-img" />
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  props: {
    desktopDarkImage: {
      type: String,
      required: false,
    },
    desktopLightImage: {
      type: String,
      required: false,
    },
    mobileDarkImage: {
      type: String,
      required: false,
    },
    mobileLightImage: {
      type: String,
      required: false,
    },
  },
  computed: {
    isMobileScreen() {
      return this.$screen.width < 780;
    },
    isThemeDark() {
      const { skin } = useAppConfig();

      return skin.value === "dark";
    },
  },
};
</script>

<style scoped>
.right-content-image {
  width: 80%;
  border-radius: 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mobile-img {
  width: 55%;
  position: relative;
  left: 21.5%;
}
.right-content {
  background: linear-gradient(to right, #28c76f, #66d799);
  width: 40%;
  border-radius: 0 7px 7px 0;
}
</style>
