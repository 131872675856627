import Vue from "vue";
import dateHelpers from "./date-helpers";
import formatters from "./formatters";

export default {
  install: () => {
    Vue.prototype.$dateHelpers = dateHelpers;
    Vue.dateHelpers = dateHelpers;
    Vue.prototype.$formatters = formatters;
    Vue.formatters = formatters;
  },
};
