import { isString } from "@vueuse/core";
import Vue from "vue";

export const TWENTYFOUR_HOURS_IN_MILLISECONDS = 86400000;
export const PAYMENT_EXTRA_FEE_IN_EUR = "payment_extra_fee_in_eur";
export const PAYMENT_EXTRA_FEE_PERCENT = "payment_extra_fee_percent";

(() => {
  console.info(`app:env`, {
    VUE_APP_API_URL: process.env.VUE_APP_API_URL,
    VUE_APP_EPG_URL: process.env.VUE_APP_EPG_URL,
    VUE_APP_PAYPAL_MODE: process.env.VUE_APP_PAYPAL_MODE,
    VUE_APP_PAYPAL_CLIENT_ID: process.env.VUE_APP_PAYPAL_CLIENT_ID,
    VUE_APP_FACEBOOK_CLIENT_ID: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
    VUE_APP_GOOGLE_CLIENT_ID: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  });
})();

export const getObjectFromLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return undefined;
  }
};

export const roundPrice = (price = 0) => {
  return Math.round(price * 100) / 100;
};

const errorHandle = (errorMessage) => {
  if (!errorMessage) return "";
  const final = apiErrorToString(errorMessage);
  if (isString(final)) {
    return Vue.$toast.error(final);
  }
  if (final instanceof Object) {
    const errorArray = Object.values(final);
    return errorArray.forEach(errorHandle);
  }
  if (final instanceof Array) {
    return final.forEach(errorHandle);
  }
};

export const apiErrorHandler = (error) => {
  const status = error?.response?.status;
  if (status < 400 || status > 499) {
    console.error(error);
    return;
  }
  errorHandle(
    error.response?.data?.message ?? error.response?.data?.data?.message
  );
};

export const EXPIRE_NOTIFIFICATION_THRESHOLD_BY_DAY = 7;

export const isBeforeExpirationThreshold = (date) => {
  const expirationThresholdDate = new Date();

  expirationThresholdDate.setDate(
    expirationThresholdDate.getDate() + EXPIRE_NOTIFIFICATION_THRESHOLD_BY_DAY
  );
  expirationThresholdDate.setHours(0, 0, 0, 0);

  return new Date(date).toJSON() < expirationThresholdDate.toJSON();
};

export const isDiffMoreThanOneDay = (date) => {
  const dateDiff = new Date();

  dateDiff.setDate(dateDiff.getDate() - 1);

  return new Date(date).toJSON() > dateDiff.toJSON();
};

export const changeTextColor = (itemStatus) => {
  const statusColorMap = {
    completed: "text-success",
    pending: "text-info",
    failed: "text-danger",
    ACTIVE: "text-success",
    INACTIVE: "text-warning",
    PENDING: "text-info",
    CANCELED: "text-danger",
    CANCELLED: "text-danger",
    CREATED: "text-info",
    COMPLETED: "text-success",
    FAILED: "text-danger",
  };
  return statusColorMap[itemStatus] ?? "text-info";
};

export const pad = (str, fillNumber, fillValue) =>
  str.padStart(fillNumber, fillValue);

export const getFirstDayOfMonth = (date = new Date()) => {
  const fullYear = date.getFullYear();
  const month = pad(`${date.getMonth() + 1}`, 2, "0");
  const day = pad("1", 2, "0");

  return `${fullYear}-${month}-${day}`;
};

const apiErrorToString = (errorMessage) =>
  errorMessage ? JSON.parse(JSON.stringify(errorMessage)) : errorMessage;

export const sortArray = (array, attribute) =>
  [...array].sort((a, b) =>
    a?.[attribute]?.toLowerCase() > b?.[attribute]?.toLowerCase() ? -1 : 1
  );

export const mapBooleanQueryParameter = (query, attribute) => {
  if (query?.[attribute] === undefined) {
    return undefined;
  }
  return query?.[attribute] === "true";
};

export const arrayToObject = (array) => {
  const object = {};
  array.forEach((item) => {
    for (let key in item) {
      object[key] = item[key];
    }
  });
  return object;
};

export const onUserBanToggle = async (checked, user, banUserToggle) => {
  await Vue.swal({
    title: `${checked ? "Ban" : "Restore"} ${user.name}.`,
    text: `Are you sure? ${
      checked
        ? "This user will not be able to log in"
        : "This user is going to able to log in"
    }`,
    type: "warning",
    icon: "warning",
    input: checked && "text",
    inputPlaceholder: "Reason",
    inputValidator: (value) => {
      if (!value) {
        return "You need to write something.";
      }
    },
    showCancelButton: true,
    showCloseButton: true,
    focusConfirm: true,
    confirmButtonColor: "#4BB543",
    confirmButtonText: `Yes, ${checked ? "ban" : "restore"} it.`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        console.log(user.id);
        await banUserToggle({
          id: user.id,
          ban: checked,
          ban_reason: String(result.value),
        });
      } catch (e) {
        console.error(e);
        apiErrorHandler(e);
      }
    }
  });
};

export const onUserInactivateToggle = async (
  checked,
  user,
  inactivateUserToggle
) => {
  await Vue.swal({
    title: `${checked ? "Inactivate" : "Activate"} ${user.name}.`,
    text: `Are you sure? ${
      checked ? "This user will be inactivated" : "This user will be activated"
    }`,
    type: "warning",
    icon: "warning",
    input: checked && "text",
    inputPlaceholder: "Reason",
    inputValidator: (value) => {
      if (!value) {
        return "You need to write something";
      }
    },
    showCancelButton: true,
    showCloseButton: true,
    focusConfirm: true,
    confirmButtonColor: "#4BB543",
    confirmButtonText: `Yes, ${checked ? "inactivate" : "activate"} it.`,
  }).then(async (result) => {
    if (result.value) {
      try {
        await inactivateUserToggle({
          id: user.id,
          inactivate: checked,
          inactive_reason: String(result.value),
        });
      } catch (e) {
        console.error(e);
        apiErrorHandler(e);
      }
    }
  });
};
